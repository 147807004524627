import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import ModulesSlides from "../components/modules/ModulesSlides"
// import Slides from "../components/modules/Slides"

export const query = graphql`
  query PageQ($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
      # title
      layout

      slides {
        project {
          title
          category {
            title
          }
        }
        modules {
          ... on SanityImageUI {
            _type
            _key
            title
            layout
            layoutArea
            gridArea
            size
            align
            image: _rawImage(resolveReferences: { maxDepth: 10 })
            caption: _rawCaption(resolveReferences: { maxDepth: 10 })
          }
          ... on SanityTexteUI {
            _type
            _key
            title
            layout
            layoutArea
            gridArea
            texte: _rawTexte(resolveReferences: { maxDepth: 10 })
          }
          ... on SanityVideoUI {
            _key
            _type
            layout
            layoutArea
            gridArea
            url
            caption: _rawCaption(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  }
`

const Page = ({ data }) => {
  const { slug, seo, home, layout, slides } = data.sanityPage
  return (
    <div className="page">
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`template-page page-${slug.current} ${
            home ? "home" : ""
          } layout-${layout}`}
          page={true}
        />
      )}
      {/* <h1>Page</h1>
      <pre>{JSON.stringify(modules)}</pre> */}
      {/* <ModulesScroll input={modules} /> */}
      {/* {home && (
        <div className="header top-header-height fixed left-0 z-10 flex">
          <div className="category cartouche-box">
            <div className="label">category</div>
          </div>
          <h1 className="cartouche-box b-t">
            <div className="label ellipsis">label</div>
          </h1>
        </div>
      )} */}
      <ModulesSlides input={slides} />
    </div>
  )
}

export default Page
